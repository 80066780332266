/* Home Page Css */

.homeBannerImage{
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 4px;
}

.homeAffiliationImage{
    aspect-ratio: 150 / 70;
    max-height: 70px;
    object-fit: contain;
}
.trustImage, .benefitsImage , .serviceImage{
    aspect-ratio: 1/1;
    object-fit: contain;
}

.searchEngineImage{
    /* max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%; */
    aspect-ratio: 1/1;
    object-fit: contain;
}


/* About Page Css */

.locationImage{
    /* max-width: 70px;
    max-height: 90px;
    width: 100%;
    height: 100%; */
    object-fit: cover;
    border-radius: 5px;
}

.bannerSwiperImage{
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10px;
}

/* Contact-Us */
