/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'); */

body {
  font-family: "Inter", sans-serif;
  /* background-color: #f3f6f9; */
  background: #fff;
}

a {
  color: inherit;
}

.no-spin-buttons::-webkit-outer-spin-button,
.no-spin-buttons::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spin-buttons {
  -moz-appearance: textfield;
}

.suspenece-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-form-item .ant-form-item-label > label {
  font-size: 0.87rem;
  color: #344054;
}

.ant-form-item .ant-form-item-explain,
.ant-form-item .ant-form-item-extra {
  font-size: 0.87rem;
}

.ant-upload-drag-container .ri-upload-cloud-2-fill {
  font-size: 24px;
  color: #9a9fb8;
}

.ant-card-contain-grid {
  border-radius: 10px;
}

.ant-card .ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 0;
  box-shadow: none;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
  font-weight: 600;
}

.fontW600 {
  font-weight: 600;
}

.text-blue {
  color: rgba(26, 55, 96, 0.88);
}

/* .ant-input {
  padding: 9px 14px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.ant-input.ant-input-sm {
  padding: 4px 8px;
}
.ant-input::placeholder,
.ant-select .ant-select-selection-placeholder {
  color: #667085;
} */
.ant-layout {
  background: transparent;
}

.ant-layout main {
  padding: 40px;
}

.ant-layout-sider {
  height: max-content;
  min-height: 100vh;
  border-right: 1px solid #eaecf0;
  padding: 30px 20px;
  position: sticky !important;
  top: 0;
}

.anychart-credits {
  display: none;
}

/* .ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 40px;
} */
.ant-menu {
  font-size: 14px;
  padding: 16px 12px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 16px !important;
}

.ant-menu .ant-menu-item .ant-menu-item-icon + span,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu .ant-menu-item .anticon + span,
.ant-menu .ant-menu-submenu-title .anticon + span {
  margin-left: 8px;
}

.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item .ant-menu-item-icon {
  font-size: 16px;
}

.ant-layout-sider .ant-menu-item {
  margin-block: 2px !important;
  height: 30px !important;
}

.ant-layout-sider .ant-menu-item-group-title {
  padding: 6px 16px;
}

/* .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 12px;
} */
.ant-message-notice {
  text-align: right;
  padding-right: 25px;
  padding-top: 15px;
}

/* .ant-btn {
  display: inline-flex;
  padding: 10px 18px;
  align-items: center;
  justify-content: center;
} */
.ant-btn-primary {
  box-shadow: 0 2px 2px rgb(1 9 14 / 15%);
}

.ant-table-cell-product + .ant-table-cell-product {
  border-top: 1px solid #e8e8e8;
}

.ant-table-cell .ant-btn {
  height: auto;
  font-size: 12px;
  padding: 4px 10px;
  /* padding: 0px 8px; */
  border-radius: 6px;
}

/* .ant-table-cell a:hover {
  text-decoration: underline;
} */

.ant-btn [class^="ri-"],
.ant-btn [class*=" ri-"] {
  display: inline-block;
  margin-right: 3px;
}

.ant-btn.ant-btn-sm {
  font-size: 12px;
  height: 30px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 12px;
  color: #667085;
}

.ant-table-wrapper:not(.table-inner) .ant-table {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: auto;
}

.ant-table-wrapper.table-inside-card .ant-table {
  border: none;
  box-shadow: none;
  /* border-radius: 0; */
  margin-top: 1px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.ant-table-wrapper.table-inside-card .ant-table-container table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
}

.ant-table-wrapper.table-inside-card .ant-table-container table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.table-inside-card .ant-table-cell {
  padding: 8px 12px !important;
}

.ant-table-wrapper.table-inside-card-addons .ant-table {
  border: none;
  box-shadow: none;
  /* border-radius: 0; */
  margin-top: 1px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.ant-table-wrapper.table-inside-card-addons .ant-table-container table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
}

.ant-table-wrapper.table-inside-card-addons .ant-table-container table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.table-inside-card-addons .ant-table-cell {
  padding: 8px 12px !important;
}

.table-inside-card-addons .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 0px !important;
}

.table-inside-card-addons .indent-level-1 + .ant-table-row-expand-icon {
  visibility: hidden;
}

.page-listing .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 0px !important;
}

.page-listing .indent-level-1 + .ant-table-row-expand-icon {
  visibility: hidden;
}

.expand-warehouse-table .ant-table {
  border: none !important;
  box-shadow: none !important;
}

.profile-details-card {
  border: 1px solid #e8e8e8 !important;
}

.profile-details-card .ant-card-body {
  /* padding: 2rem 2.25rem; */
  border-radius: 12px;
}

.profile-details-card .ant-card-body .title {
  color: #030229;
  font-weight: 500;
  /* font-size: 16px; */
}

.status-tag {
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: normal;
}

.profile-details-card .details {
  color: #030229;
  font-weight: 400;
  /* font-size: 16px; */
}

.title {
  color: rgba(26, 55, 96, 0.88);
  font-weight: 600;
  /* font-size: 16px; */
}

.details {
  color: #99a1b7;
  font-weight: 500;
  font-size: 14px;
}

.text-030229 {
  color: #030229;
}

.text-03022903 {
  color: #0302294d;
}

.card-title-custom .ant-card-head-title {
  /* color: #030229; */
  font-size: 14px;
  font-weight: 600;
}

.card-title-custom .ant-card-body {
  border-radius: 12px;
}

.card-title-custom .ant-card {
  border: 1px solid #e8e8e8;
}

.card-title-custom {
  /* padding: 13px; */
  border-radius: 12px;
  border: 1px solid #e8e8e8 !important;
}

.ant-tabs-tab-btn {
  /* font-size: 16px; */
  color: rgba(26, 55, 96, 0.88);
}

.tabs-custom .status-icon-box {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-custom .vendor-po-title {
  color: #4f4e6a;
  font-weight: 700;
  font-size: 16px;
  line-height: 2rem;
  margin-bottom: 0;
}

.tabs-custom .vendor-po-desc {
  color: #4f4e6a;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;
}

.background-5B93FF {
  background-color: #eff4ff;
}

.background-FFF7E1 {
  background-color: #ffd56b3f;
}

.background-FFFBF9 {
  background-color: #ff906b3a;
}

.border-bottom {
  border-bottom: 0.5px solid #03022919;
}

/* customer details css end */
.ant-row {
  min-width: 100%;
}

.ant-steps-vertical .ant-steps-item {
  padding-bottom: 50px;
}

.mt40 {
  margin-top: 40px !important;
}

.mt6 {
  margin-top: 6px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt16 {
  margin-top: 16px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt30 {
  margin-top: 30px;
}

.mt-2 {
  margin-top: 2px;
}

.mb-9 {
  margin-bottom: 9px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb28 {
  margin-bottom: 28px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb2 {
  margin-bottom: 2px;
}

.me4 {
  margin-right: 12px;
}

.me1 {
  margin-right: 4px;
}

.my10 {
  margin: 10px 0;
}

.mx16 {
  margin: 0 16px;
}

.mx26 {
  margin: 0 26px;
}

.pt10 {
  padding-top: 10px;
}

.pb16 {
  padding-bottom: 16px;
}

.pb24 {
  padding-bottom: 24px;
}

.p-4 {
  padding: 4px;
}

.borderBottomDotted {
  border-bottom: 1px dotted;
}

.linkHover:hover {
  text-decoration: underline;
}

.borderBottom-ebebeb {
  border-bottom: 1px solid #ebebeb;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.fontWeight600 {
  font-weight: 600;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.underline {
  text-decoration: underline;
}

.height-100 {
  height: 100%;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap30 {
  gap: 30px;
}

.mandatory-sign-mark::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 13px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  margin-left: 4px;
}

.login-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  /* background-color: #f3f6f9; */
  align-items: center;
  /* background-color: #a099ff;
  background-image: radial-gradient(at 48% 74%,
      hsla(253, 90%, 67%, 1) 0px,
      transparent 50%),
    radial-gradient(at 85% 24%, hsla(269, 76%, 75%, 1) 0px, transparent 50%),
    radial-gradient(at 55% 58%, hsla(39, 62%, 65%, 1) 0px, transparent 50%),
    radial-gradient(at 85% 4%, hsla(262, 88%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 10% 1%, hsla(184, 84%, 60%, 1) 0px, transparent 50%),
    radial-gradient(at 39% 5%, hsla(13, 83%, 78%, 1) 0px, transparent 50%),
    radial-gradient(at 51% 9%, hsla(288, 71%, 77%, 1) 0px, transparent 50%); */
}

.login-banner-wrapper-full {
  background-image: url('./images/login-banner-full.png');
  background-repeat: no-repeat;
  height: calc(100vh - 50px);
  width: 100%;
  background-size: cover;   
  background-position: left;
  border-radius: 8px;
}

.login-card-new-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin: 60px auto;
  max-width: 400px;
  row-gap: 30px;
}

.login-card-new-full .logo {
  width: 200px;
}
.login-card-new-full .welcome-title-full {
  font-size: 24px;
  color: #000000;
}

.login-wrapper-full  {
  padding: 24px;
}

.login-card {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #e3e6e9;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.login-card-new {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  max-width: 400px;
  row-gap: 30px;
}


.login-card .logo {
  width: 120px;
}

.login-card-new .logo {
  width: 130px;
}

.login-banner {
  border-radius: 24px;
  height: calc(100vh - 40px);
  object-fit: cover;
  width: 100%;
}

.login-logo {
  width: 200px;
}

.sidebar-custom-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header-user-card-container {
  border-radius: 6px;

  padding: 5px 10px;
}
.login-card-new > .login-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media all and (min-width: 1200px) {
  .header-user-card-container {
    min-width: 180px;
    background-color: #f4f4f4;
  }
}
@media all and (device-width: 1024px) and (orientation: landscape) {
  .login-wrapper {
    padding: 40px;
  }
  .login-banner-wrapper {
    display: none;
  }
  .login-card-wrapper {
    border-radius: 30px;
    background-color: #f2f5fd;
    background-image: url('images/login-img-ipad.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    display: flex;
    align-items: top;
    padding-top: 80px;
    justify-content: center;
    z-index: 999;
  }

  .login-card-new > .login-form-wrapper .logo {
    width: 200px;
  }
  .login-card-new > .login-form-wrapper {
    align-items: center;
  }
  .login-card-new > .login-form-wrapper .welcome-title {
    font-size: 38px;
  }
  .login-card-new {
    max-width: 300px;
    width: 100%;
  }
  .login-card-new .ant-btn-lg {
    font-size: 18px;
  }
  .login-wrapper.login-wrapper-old .login-card-new
    > .login-form-wrapper .welcome-title {
    font-size: 22px;
  }
  .login-wrapper.login-wrapper-old .login-card-new {
    max-width: 450px;
  }
  .login-wrapper.login-wrapper-old .login-card-new form {
    width: 100%;
    background: #ffff;
    padding: 40px;
    box-shadow: 0px 4px 98.2px 32px #00000012;
    border-radius: 17px;
  }
}
@media all and (device-width: 768px) and (orientation: portrait) {
  .login-wrapper {
    padding: 40px;
  }
  .login-banner-wrapper {
    display: none;
  }
  .login-card-wrapper {
    border-radius: 30px;
    background-color: #f2f5fd;
    background-image: url('images/login-img-ipad.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    display: flex;
    align-items: top;
    padding-top: 80px;
    justify-content: center;
    z-index: 999;
  }
  .login-card-new {
    max-width: 500px;
    width: 100%;
  }
  .login-card-new > .login-form-wrapper .logo {
    width: 200px;
  }
  .login-card-new > .login-form-wrapper {
    align-items: center;
  }
  .login-card-new > .login-form-wrapper .welcome-title {
    font-size: 38px;
  }
  .login-card-new .ant-btn-lg {
    font-size: 18px;
  }
  .login-wrapper.login-wrapper-old .login-card-new
    > .login-form-wrapper .welcome-title {
    font-size: 22px;
  }
  .login-wrapper.login-wrapper-old .login-card-new {
    max-width: 450px;
  }
  .login-wrapper.login-wrapper-old .login-card-new form {
    width: 100%;
    background: #ffff;
    padding: 40px;
    box-shadow: 0px 4px 98.2px 32px #00000012;
    border-radius: 17px;
  }
}

.header-user-card {
  /* border: 1px solid #d0d5dd;  */
  border-radius: 8px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-details {
  max-width: 110px;
}

.user-details-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  align-items: center;
}

.user-details-name {
  font-size: 12px;
  /* color: #1a3760; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.user-details-email {
  font-size: 10px;
  /* color: #1a3760; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  /* padding-left: 16px;
  padding-right: 16px; */
  /* padding-left: 8px;
  padding-right: 10px; */
  padding-left: 7px;
  padding-right: 17px;
}

.page-header-wrapper .page-header-title {
  font-size: 24px;
  font-weight: bold;
}

.page-header-wrapper .page-header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media all and (device-width: 1024px) and (orientation: landscape) {
  .page-header-wrapper {
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .page-header-wrapper .page-header-title > div {
    margin-left: 0 !important;
  }
  .page-header-wrapper .page-header-action,
  .page-header-wrapper .page-header-actions {
    width: 100%;
  }
  .page-header-wrapper .page-header-action > div {
    justify-content: space-between;
  }
}
@media all and (device-width: 768px) and (orientation: portrait) {
  .page-header-wrapper {
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .page-header-wrapper .page-header-title > div {
    margin-left: 0 !important;
  }
  .page-header-wrapper .page-header-action,
  .page-header-wrapper .page-header-actions {
    width: 100%;
  }
  .page-header-wrapper .page-header-action > div {
    justify-content: space-between;
  }
}
.search-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 350px;
}

.card-checkbox .ant-checkbox-wrapper {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 15px 28px 15px 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.card-checkbox .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.card-checkbox .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.card-checkbox .ant-checkbox-checked:after {
  border-radius: 100%;
}

.card-checkbox .ant-checkbox-wrapper .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner {
  border-color: transparent;
}

.card-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #e6f4ff;
  border-color: #1677ff;
  border-width: 2px;
  padding: 14px 27px 14px 9px;
  /* border-width: 2px; */
}

.report_type_checkboxes .ant-checkbox-wrapper {
  padding: 15px 28px 15px 28px;
}

.report_type_checkboxes .ant-checkbox + span {
  width: 100%;
  text-align: center;
}

.report_type_checkboxes .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding: 14px 27px 14px 27px;
}

.marketplace-region {
  color: #8996a9;
}

.antd-table-filter-dropdown {
  padding: 15px 10px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.appplied-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.applied-filter-tag {
  font-size: 10px;
  font-weight: 400;
}

.applied-filter-value {
  font-size: 10px;
  font-weight: 700;
}

.applied-filters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
}

.applied-filter-wrap {
  padding: 8px;
  background-color: #ffffff;
  color: #132237;
  border-radius: 8px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  border: 1px solid #e3e6e9;
}

.applied-filter-wrap > span {
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.applied-filter {
  font-weight: bold;
}

.applied-filter > span {
  font-size: 10px;
  font-weight: normal;
  margin-right: 10px;
}

.applied-filter-wrap .applied-filter-remove {
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
  cursor: pointer;
}

.detail-block {
  line-break: anywhere;
}

.detail-block > label,
.detail-block > span {
  display: block;
}

.detail-block > label {
  font-size: 14px;
  color: #667085;
  margin-bottom: 3px;
}

.detail-block > span {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

.marketplace-detail-card,
.report-type-wrapper {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 15px 28px 15px 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  position: relative;
  font-weight: normal;
  text-align: center;
}

.report-type-wrapper {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 18px 20px;
  width: 100%;
  white-space: nowrap;
}

.table-tag .ant-alert {
  display: inline-flex;
  padding: 4px 8px;
}

.table-tag .ant-alert .ant-alert-content {
  font-size: 12px;
}

.product-image-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-image-wrapper {
  width: 102px;
  height: 102px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.product-image-wrapper-sm {
  width: 50px;
  height: 50px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.product-title {
  font-size: 16px;
}

.product-price {
  color: #1677ff;
}

.product-compatibility-image {
  /* border: 1px solid rgba(235, 235, 235, 1); */
  min-height: 80px;
  min-width: 80px;
  /* object-fit: cover; */
  object-fit: contain;
  /* border-radius: 6px; */
  aspect-ratio: 1 / 1;
}

.detail-block > label {
  /* font-weight: 600; */
}

.detail-block ul {
  padding-inline-start: 16px;
  list-style-type: decimal;
}

.detail-block ul li {
  font-size: 18px;
  font-weight: bold;
}

.pd-identifier {
  color: #667085;
  margin-bottom: 10px;
  display: block;
}

.pd-product-name {
  font-weight: bold;
  font-size: 20px;
}

.ant-descriptions-item-content ul {
  padding-left: 18px;
}

.pd-status-wrap {
  display: inline-block;
  margin-bottom: 20px;
}

.amazon-serach-modal-search .ant-input-group .ant-input-group-addon .ant-select {
  margin: 0;
  border: 1px solid #d9d9d9;
  border-radius: 8px 0 0 8px;
  border-right: 0px;
}

.amazon-serach-modal-search .ant-input-group .ant-input-group-addon {
  padding: 0;
  border: 0;
}

.amazon-serach-modal-search .ant-input-group .ant-input {
  height: 46px;
}

.amazon-serach-modal-search .ant-input-group .ant-input-group-addon .ant-btn {
  border-radius: 0 8px 8px 0;
  border-left: 0;
  height: 46px;
}

.read-more-btn {
  background-color: transparent;
  border: none;
  color: #8996a9;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.w-100 {
  width: 100%;
}

@media all and (min-width: 576px) {
  .login-card {
    width: 480px;
    padding: 40px;
    padding-bottom: 50px;
  }
}

.ant-checkbox-wrapper {
  align-items: center;
}

.ant-form-item-required::before {
  order: 1;
  margin-right: 0;
  margin-left: 4px;
}

.ant-list-bordered {
  overflow: hidden;
}

.prdouct-info-btn {
  display: flex;
  justify-content: flex-end;
}

.password-field label {
  width: 100%;
}

.password-field .password-field-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.password-field .ant-form-item-extra {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}

.ant-layout .ant-layout-sider-light {
  background-color: #ffffff;
  padding: 0;
  /* border: 0; */
}

.ant-layout .ant-layout-sider-light .sidebar-logo-wrapper,
.ant-layout-header {
  height: 56px;
}

.ant-layout-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  line-height: 1.25;
  padding: 0 16px;
  z-index: 1;
  border-bottom: 1px solid #eaecf0;
}

.ant-layout .ant-layout-sider-light .sidebar-logo-wrapper {
  padding: 5px 16px;
}

.ant-layout .ant-layout-sider-light .sidebar-logo-wrapper img {
  max-height: 100%;
  margin: 0;
}

.ant-layout-sider .ant-menu-item {
  font-weight: 500;
}

.ant-pagination .ant-pagination-item {
  border-radius: 6px;
}

.ant-card {
  box-shadow: rgba(26, 26, 26, 0.07) 0px 1px 0px 0px;
  border: 1px solid rgb(204, 204, 204, 0.5);
}

.ant-picker {
  width: 100%;
}

.ant-list-bordered {
  background-color: #fff;
}

.address-make-default-btn {
  opacity: 0;
  transition-duration: 0.3s;
}

.ant-collapse-header:hover .address-make-default-btn {
  opacity: 1;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  aspect-ratio: 1/1;
}

.ant-upload-list {
  margin-top: 8px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 80px;
  height: 80px;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 8px;
  padding: 6px;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail {
  border-radius: 4px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  top: 0;
  left: 0;
  border-radius: 6px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm {
  padding: 10px 5px;
  width: auto;
  height: auto;
  background-color: #ffffff29;
  border: 1px solid #ffffff50;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item {
  padding-left: 38px !important;
}

.form-remove-btn {
  margin-top: 32px;
  font-size: 18px;
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
  padding: 5px 3px;
  font-family: "Inter", sans-serif;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border-radius: 5px;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border-radius: 8px;
}

.ql-container {
  font-family: "Inter", sans-serif;
}

.ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
}

.ql-editor {
  min-height: 5rem;
  max-height: 30rem;
  overflow-y: auto;
}

.hamburger-responsive {
  /* border: 1px solid #4c535e; */
  background-color: #2b2f35;
  height: 37px;
  width: 37px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
}

.nav-logo-responsive {
  max-height: 37px;
  width: auto;
}

.form-tab-section {
  margin: 24px 0px;
}

.form-tab-section .ant-tabs-nav {
  margin-bottom: 0px;
  /* z-index: 1; */
  /* border-bottom: 1px solid rgb(204, 204, 204, 0.5); */
}

.form-tab-section .ant-tabs-tabpane > .ant-card {
  background: transparent;
  border-top-right-radius: 0%;
  border-top-left-radius: 0%;
  /* border-top: none; */
  position: relative;
  /* z-index: -1; */
}

.form-tab-section .ant-tabs-tab-active {
  background: #f3f6f9 !important;
  border: 1px solid rgb(204, 204, 204, 0.5) !important;
  border-bottom: none !important;
}

.form-tab-section .ant-tabs-nav {
  position: sticky;
  top: 56px;
}

.form-tab-section .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: rgb(204, 204, 204, 0.5);
}

.form-tab-section .ant-tabs-nav-wrap {
  background: #f3f6f9;
}

.form-tab-section .ant-tabs-tab-active::after {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -4px;
  /* border-bottom: 3px solid #172539; */
  border: 3px solid #f3f6f9;
  content: "";
  /* z-index: 2; */
}

.header-user-card-container {
  cursor: pointer;
}

.ant-menu-sub .ant-menu-item {
  height: 28px;
  line-height: 28px;
}

.ant-menu-submenu-open {
  background-color: rgba(0, 0, 0, 0.02);
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  opacity: 0;
  /* height: ; */
}

.ant-menu-item-group:not(:first-child) {
  margin-top: 0px;
}

.sidebar-responsive {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  padding: 0;
  border: 1px solid #eee;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  overflow-y: auto;
}

.table-cell-link {
  font-weight: 700;
}

/* .table-cell-link:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
} */

.table-cell-product-image {
  border: 1px solid rgba(235, 235, 235, 1);
  min-height: 40px;
  min-width: 40px;
  /* object-fit: cover; */
  object-fit: contain;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
}

.ant-table-cell-with-append {
  /* display: flex; */
  align-items: center;
}

.ant-table-wrapper:not(.table-inner) .ant-table-row-indent
  + .ant-table-row-expand-icon {
  border-color: #1a3760e0;
  margin-top: 12px;
}

.ant-table-wrapper.expand-without-image .ant-table-row-indent
  + .ant-table-row-expand-icon {
  display: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: rgb(26 55 96 / 7%);
  border: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add {
  border: none;
  background: rgb(26 55 96 / 7%);
}

.purchase-order-table {
  margin-top: 12px;
}

.purchase-order-table.ant-table-wrapper .ant-table {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.purchase-order-table.ant-table-wrapper .ant-table-container,
.purchase-order-table.ant-table-wrapper .ant-table-container table,
.purchase-order-table.ant-table-wrapper .ant-table-container table
  > thead
  > tr:first-child
  > *:first-child,
.purchase-order-table.ant-table-wrapper .ant-table-container table
  > thead
  > tr:first-child
  > *:last-child {
  border-radius: 0;
}

.purchase-order-table .ant-table-cell {
  vertical-align: top;
}

.recieve-order-progress .ant-progress .ant-progress-success-bg {
  background-color: rgba(41, 132, 90, 1);
}

.recieve-order-progress .ant-progress-bg {
  background-color: rgba(181, 38, 11, 1);
}

.inventory-unavaible-card .ant-card-body {
  padding: 4px;
}

.inventory-unavaible-card .ant-card-head {
  padding: 4px;
  min-height: auto;
}

.order-user-card .ant-card {
  border: 0px;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.order-user-card .ant-card:hover {
  border: 0px;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.order-user-card .ant-card .ant-card-body {
  padding: 8px;
  border-radius: 0;
}

.order-user-link-card .ant-card {
  border: 0px;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.order-user-link-card .ant-card:hover {
  border: 0px;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.order-user-link-card .ant-card .ant-card-body {
  padding: 8px;
  border-radius: 0;
}

.collection-product-table .ant-table-container .ant-table-thead {
  display: none;
}

.collection-product-table {
  margin-top: 24px;
}

.comment-section .comment-section-input {
  border: none;
  padding-left: 0;
}

.comment-section .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.comment-section .ant-input:focus {
  box-shadow: none;
}

.comment-section .ant-card-small > .ant-card-body {
  padding: 0;
}

.comment-section .comment-section-top {
  padding: 12px;
}

.comment-section .comment-section-bottom {
  padding: 12px;
  background-color: #f3f6f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.comment-section-block {
  position: absolute;
  inset-block-start: 0px;
  inset-inline-start: 4px;
  height: 40px;
  border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}

.log-inventory_received .ant-table-wrapper:not(.table-inner) .ant-table {
  border: none;
  box-shadow: none;
  border-radius: 0px;
  overflow: auto;
}

.log-inventory_received .ant-table-wrapper .ant-table-thead > tr > th,
.log-inventory_received .ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(26, 55, 96, 0.88);
  font-weight: 600;
  text-align: start;
  background: transparent;
  border-bottom: none;
}

.log-inventory_received .ant-table-wrapper .ant-table {
  background: transparent;
}

.log-inventory_received .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none;
}

.log-inventory_received .ant-table-wrapper .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

.log-inventory_received .log-inventory_received-description:hover {
  text-decoration: underline;
  cursor: pointer;
}

.log-inventory_received .log-inventory_received-arrow:hover {
  cursor: pointer;
}

.log-inventory_received .log-inventory_received-description-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mt3 {
  margin-top: 3px;
}

/* product page start */
.product-add-page .ant-select-selector .ant-select-selection-search-input {
  height: 50px !important;
}
.product-add-page .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 40px !important;
}
.product-variation-table {
  margin-top: 24px;
}
.product-variation-table.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell {
  padding: 2px 8px;
}
.product-variation-table.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell
  .ant-form-item {
  margin: 0;
}
.product-variation-table .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
.product-variation-table .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 60px;
  height: 60px;
}

.product-variation-table .ant-upload-select strong {
  font-weight: 400;
}
.product-variation-table .ant-table {
  overflow: unset !important;
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
/* product page end */

/* cms page form css start */
.dashed-border {
  /* border:1px dashed #8996a9; */
  margin-top: 20px;

  border-top: 1px dashed #8996a9;
}

.form-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 8px;
}

.form-description {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.form-box {
  background-color: white;
  padding: 32px;
}

/* cms page form css end */

/* sticky footer css start */
.sticky-footer {
  position: sticky;
  bottom: 0;
  backdrop-filter: blur(9px);
  background-color: rgba(243, 244, 246, 0.1);
  text-align: right;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  z-index: 999;
}

.sticky-footer-padding {
  position: sticky;
  bottom: 0;
  backdrop-filter: blur(9px);
  background-color: rgba(243, 244, 246, 0.1);
  text-align: right;
  padding: 1.5rem 3.2rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  margin-left: -2rem;
  margin-right: -2rem;
}

.ant-card .ant-card-actions > li {
  margin: 4px 0;
}

.ant-card .ant-card-head-title {
  overflow: visible;
}

.google-places-autocomplete {
  margin-bottom: 24px;
}

.google-places-autocomplete div[class$="control"]::before {
  width: 24px;
  height: 24px;
  content: "";
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg");
  background-size: 24px 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  pointer-events: none;
}

.google-places-autocomplete div[class$="control"] {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 8px;
  transition: all 0.2s;
  min-height: 32px;
  height: auto;
  padding-left: 32px;
}

.google-places-autocomplete div[class$="indicatorContainer"] {
  padding: 6px;
}

.google-places-autocomplete div[class$="Input"] {
  margin: 0;
}

/* sticky footer css end */

/* filter section start */
.filter-section {
  margin: 16px 0px;
}

.filter-section .ant-card .ant-card-body {
  padding: 4px;
}

.filter-section .ant-btn.ant-btn-sm {
  padding: 5.5px 8px;
}

.filter-section .ant-btn.ant-btn-sm.ant-btn-text {
  padding: 5.5px 12px;
  font-weight: 600;
}

.filter-section-head {
  border-bottom: 1px solid rgb(204, 204, 204, 0.5);
  padding: 10px 10px 10px 10px;
}

/* filter section end */

/* Vendor View start */
.vendor-tabs .ant-tabs-nav {
  margin: 0;
}

.vendor-tabs .ant-tabs-tab {
  padding: 0px 0px 12px 0px;
}

.status-icon-box {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-po-title {
  color: #4f4e6a;
  font-weight: 700;
  font-size: 16px;
  line-height: 2rem;
}

.vendor-po-desc {
  color: #4f4e6a;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;
}

.background-fff7e6 {
  background-color: #fff7e6;
}

.background-eff5ff {
  background-color: #eff5ff;
}

.background-ffe9e1 {
  background-color: #ffe9e1;
}

.vendor-table .ant-table-wrapper .ant-table-thead > tr > th {
  border-top: 1px solid #f0f0f0;
  /* background: #E8E8E8; */
}

.vendor-table .ant-table-wrapper .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  height: 0;
  width: 0;
}

/* Vendor View end */

/* Settings Start */
.header-setting .menu-icon-box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-setting .menu-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 2rem;
  margin-bottom: 0;
}

.background-ff6b6b3a {
  background-color: #ff8b8b3a;
  color: rgb(235 29 29);
}

.background-6bff6b3a {
  background-color: #80ff803a;
  color: #15b41e;
}

.background-5572a3 {
  background-color: #5572a324;
  color: #5572a3;
}

/* Settings end */

/* Order Refund Start */
.order-refunds-table >.ant-collapse-item >.ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}
/* Order Refund End */

/* Add Order Screen Start */
.add-order-spin {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-order-container {
  margin-top: 20px;
}

.total-container {
  padding: 20px 20px 0px 20px;
}

.total-container .main-title {
  font-size: 14px;
  font-weight: 600;
}

.add-order-container .ant-card-body {
  height: 100%;
}

.no-address-available {
  /* border: 1px dashed gray; */
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.add-order-container .product-image {
  border: 1px solid rgba(235, 235, 235, 1);
  min-height: 80px;
  min-width: 80px;
  /* object-fit: cover; */
  object-fit: contain;
  border-radius: 6px;
  aspect-ratio: 1 / 1;
  z-index: 0;
  width: 100%;
}
.add-order-container .ant-image {
  width: 100%;
}

.add-order-container .product-price {
  color: #ffffff;
  background-color: #b12525;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
  position: absolute;

  z-index: 1;
}

.add-order-container .product-quantity {
  color: #027a48;
  background-color: #ecfdf3;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  right: 12px;
}

.checkout-address .ant-radio-checked + span > .ant-card {
  border-color: #b12525;
}

.checkout-address .ant-radio {
  display: none;
}

.checkout-address .ant-radio + span {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  height: 100%;
  width: 100%;
}
.checkout-address .ant-radio-wrapper {
  margin-inline-end: 0px;
  height: 100%;
  width: 100%;
}
.checkout-address .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.cart-drawer-btn {
  background-color: #b12525;
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: none;
  padding: 14px 12px 12px 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: fixed;
  right: 0;
  top: 45%;
  z-index: 999;
}

.cart-drawer-btn .cart-total-item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.cart-drawer-btn .cart-price {
  background-color: #ffffff;
  padding: 8px;
  margin-top: 12px;
  border-radius: 5px;
  color: #b12525;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.cart-total-item {
  color: #b12525;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.cart-drawer .ant-drawer-body {
  position: relative;
  /* overflow: initial; */
}

.cart-drawer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  overflow-y: auto;
}

.cart-drawer-total {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.cart-drawer-product-image {
  border: 1px solid rgba(235, 235, 235, 1);
  min-height: 50px;
  min-width: 50px;
  object-fit: contain;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
}

.shipping-service .ant-radio-checked + span > .ant-card {
  border-color: #b12525;
}

.shipping-service .ant-radio {
  display: none;
}

.shipping-service .ant-radio + span {
  display: block;
  width: 100%;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.default-btn-style .ant-btn-default {
  background: #ffffff;
  border-color: #b12525;
  color: rgba(17, 17, 17, 0.88);
  box-shadow: 0 2px 0 rgba(17, 17, 17, 0.02);
}

/* .default-btn-style .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
  color: #bd4a46;
    border-color: #d9d9d9;
    background: #ffffff;
} */

.add-to-cart-title {
  margin-bottom: 24px;
  color: #b12525;
  font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
}

.text-modal-b12525 {
  color: #b12525;
}

.add-to-cart-mt-4 {
  margin-top: 24px;
}
.add-to-cart-mb-2 {
  margin-bottom: 8px;
}

.core-refund-text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 8px;
}

.core-refund-text-radio {
  font-size: 14px;  
}

.order-management-product-image {
  border: 1px solid rgba(235, 235, 235, 1);
  min-height: 50px;
  min-width: 50px;
  object-fit: contain;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
}

/* Add Order Screen End */

/* Shipping Zone start */
.no-form-input-label .ant-form-item-label label {
  color: transparent;
}
.no-form-input-label .ant-form-item-label .ant-form-item-required::before {
  color: transparent !important;
}
/* Shipping Zone end */

/* CMS start */
.ant-picker {
  width: 100%;
}

.ant-upload-list {
  margin-bottom: 8px;
}

.ant-upload-picture-card-wrapper .ant-upload-drag {
  margin-bottom: 16px;
}

.ant-upload-list-item-thumbnail,
.ant-upload-list .ant-upload-list-item-container .ant-upload-list-item::before {
  border-radius: 10px;
}

.ant-tag {
  padding: 4px 10px;
  font-size: 10px;
}

.table-cell-product-image {
  border-radius: 7px;
}

.ant-form-item .ant-form-item-label > label::before {
  order: 1;
  margin-left: 4px;
  margin-right: 4px;
}

.ant-form-item .ant-form-item-label > label::after {
  order: 2;
}

.media-wrapper {
  aspect-ratio: 1;
  border: 1px solid #ccc;
  border-radius: 16px;
}

.media-wrapper .ant-image {
  width: 100%;
  border-radius: 16px;
  height: 100%;
  cursor: pointer;
}

.ant-modal-body .media-wrapper-selected {
  position: relative;
}

.ant-modal-body .media-wrapper-selected::after {
  content: "✓";
  font-size: 20px;
  position: absolute;
  color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal-body .media-wrapper-selected::before {
  content: "";
  height: 40px;
  width: 40px;
  background-color: #9d0d93;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.media-wrapper-selected {
  outline: 2px solid #9d0d93;
  border: 1px solid #fff;
  position: relative;
}

.media-wrapper-selected .ant-image {
  opacity: 0.3;
}

.media-wrapper-selected::before {
  content: "";
  width: 100%;
  height: 100%;
}

/* .ant-image .ant-image-mask {
  display: none;
}*/
.media-preview {
  border: 1px solid #ccc;
  max-width: 150px;
  aspect-ratio: 1;
  border-radius: 16px;
}

.media-preview {
  object-fit: contain;
}

.media-preview ~ .ant-image-mask {
  border-radius: 16px;
}

.media-wrapper .ant-image .ant-image-mask:hover {
  opacity: 0;
}

.media-wrapper .ant-image .ant-image-img,
.media-wrapper .ant-image .ant-image-mask {
  border-radius: 16px;
}

.media-wrapper .ant-image .ant-image-img {
  height: 100%;
  object-fit: contain;
}

.toolbar-wrapper {
  /* position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px; */
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  /* transform: translateX(-50%);  */
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

.media-wrapper .ant-skeleton-element,
.media-wrapper .ant-skeleton-element .ant-skeleton-image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.ql-disabled{
  color: rgba(17, 17, 17, 0.25);
  background-color: rgba(17, 17, 17, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

/* Update Profile Css */

.popOverIcon {
  position: absolute;
  top: -17px !important;
  left: -50px !important;
}

.popOverIcon .ant-popover {
  left: 80px !important;
  top: -5px !important;
}

.popOverIcon .ant-popover .ant-popover-content .ant-popover-inner {
  padding: 4px 8px !important;
}

.popOverIcon .ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .uploadDeleteBtn {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.popOverIcon .editIcon {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid transparent;
  position: absolute;
  left: 40px;
  top: 8px !important;
  background: #ffffff;
  box-shadow: 0px 0px 3.35509px rgba(0, 0, 0, 0.25);
  padding: 4px;
}

.uploadBtn, .deleteBtn{
  border: none;
}


/* .btnCmsPageCard{
  all: unset;
}

.btnCmsPageCard:disabled,
 btnCmsPageCard[disabled]{
  color: rgba(17, 17, 17, 0.25);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
} */

/* CMS end */
